const i18n = {
  // Advance Reports
  advanceReportsAdvertiser: 'Advertiser',
  advanceReportsAdvertiserSaas: 'Advertiser Saas',
  advanceReportsCampaign: 'Campaign',
  advanceReportsCampaignSaas: 'Campaign Saas',
  advanceReportsClient: 'Client',
  advanceReportsCampaignId: 'Campaign Id',
  advanceReportsLineItem: 'Line Item',
  advanceReportsLineItemSaas: 'Line Item Saas',  
  advanceReportsCreative: 'Creative',
  advanceReportsLineItemId: 'Line Item Id',
  advanceReportsCreativeId: 'Creative Id',
  advanceReportsFormat: 'Format',
  advanceReportsDate: 'Date',
  advanceReportsOfferType: 'Offer Type',
  advanceReportsInventoryType: 'Inventory Type',
  advanceReportsCountry: 'Country',
  advanceReportsCountryApp: 'Country App',
  advanceReportsCountryYoutube: 'Country Youtube',
  advanceReportsDevices: 'Devices',
  advanceReportsAppName: 'App Name',
  advanceReportsYoutubeChannel: 'Youtube Channel',
  advanceReportsIDHSDeal: 'ID HS Deal',
  advanceReportsImpressions: 'Impressions',
  advanceReportsViews: 'Views',
  advanceReportsGoal: 'Goal',
  advanceReportsClicks: 'Clicks',
  advanceReportsEngagements: 'Engagements',
  advanceReportsVTR: 'VTR',
  advanceReportsCTR: 'CTR',
  advanceReportsER: 'ER',
  advanceReportsInversionBudgetCurrency: 'Inversion budget Currency',
  advanceReportsInversionBudgetUSD: 'Inversion budget USD',
  advanceReportsSpentUSD: 'Spent (USD)',
  advanceReportsCurrency: 'Currency',
  advanceReportsSpentCurrencyDeal: 'Spent Currency Deal',
  advanceReportsStartDate: 'Start Date',
  advanceReportsEndDate: 'End Date',
  advanceReportsQuartiles: 'Quartiles',
  advanceReportsFirstQuartile: 'First Quartile',
  advanceReportsMidQuartile: 'Mid Quartile',
  advanceReportsThirdQuartile: 'Third Quartile',
  advanceReportsMarket: 'Market',
  advanceReportsBillingStatus: 'Billing Status',
  advanceReportsEmpty: 'Your report is currently empty.',
  advanceReportsGetStarted: 'To get started, drag and drop metrics dimensions in the relevant section.',
  advanceReportsNoMatches: 'No matches for your filters',
  advanceReportsNoDataFound: 'No data found',
  advanceReportsSelectItems: 'Select or search the items with which you want to build the report',
  advanceReportsCpm: 'CPM Usd',
  advanceReportsCpv: 'CPV Usd',
  advanceReportsCpmCurrency: 'CPM Currency',
  advanceReportsCpvCurrency: 'CPV Currency',

  
  performanceReport: 'Performance Report',
  audienceReport: 'Audience Report',
  myReports: 'My Reports',


  add: 'Add',
  app: 'Apps',
  youtube: 'Youtube',
  apply: 'Apply',
  cancel: 'Cancel',
  row: 'Row',
  column: 'Column',
  rows: 'Rows',
  columns: 'Columns',
  clearAll: 'Clear all',
  execute: 'Execute',
  enableExecuteButton: 'To enable you need to select a Date Range and have at least one Row selected',

  // Dates
  dateRange: 'Date range',
  yesterday: 'Yesterday',
  today: 'Today',
  thisMonth: 'This month',
  lastSevenDays: 'Last seven days',
  lastMonth: 'Last month',
  customDate: 'Custom date',

}

export default i18n;