import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Collapse } from "react-bootstrap";
import { MdOutlineKeyboardDoubleArrowLeft, MdOutlineKeyboardDoubleArrowRight, MdOutlineCancel } from "react-icons/md";
import rowReportsList from "../constants/rowsReportsList";
import columnsReportsList from "../constants/columnsReportsList";
import filterReportsList from "../constants/filterReportsList";
import filterReportsSaasList from "../constants/filterReportsSaasList";
import SearcherInput from "../../General/SearcherInput";
import ModalFiltersReports from "../modalFiltersReports";
import ModalCustomDate from "../constants/modalCustomDate";
import saveIcon from "../../../assets/images/saveIcon.svg";
import resetIcon from "../../../assets/images/resetIcon.svg";
import downloadIcon from "../../../assets/images/downloadIcon.svg";
import saveIconDisabled from "../../../assets/images/saveIconDisabled.svg";
import resetIconDisabled from "../../../assets/images/resetIconDisabled.svg";
import downloadIconDisabled from "../../../assets/images/downloadIconDisabled.svg";
import { getPerformanceReport, getPerformanceReportSaas } from '../../../services/reportsService';
import { downloadCsv } from '../../../utils/index';
import Spinner from 'react-bootstrap/Spinner';
import incompatibleData from '../constants/performanceReportIncompatibleData';
import dimensionMap from '../constants/dimensionsMap';
import i18n from "../../../i18n";
import TablePerformanceReport from "./tablePerformanceReport";
import Button from "../../General/Button";
import NoneSelectedItems from "./NoneSelectedItems";
import SelectedItems from "./SelectedItems";

const PerformanceReport = (props) => {
  const userCredentials = JSON.parse(localStorage.getItem('userdata'));

  const fillReportsList = (list, isSaas) => {
    return list.filter(item => {
      if (item.reportType === "both") {
        if (item.role === 1) {
          return userCredentials.role === 1;
        }
        return true;
      }
      return isSaas ? item.reportType === "saas" : item.reportType === "fixed";
    }).map(item => item.value);
  };

  const [rowTab, setRowTab] = useState(true);
	const [columnTab, setColumnTab] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsTable, setSelectedRowsTable] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [rowReports, setRowReports] = useState(fillReportsList(rowReportsList,props.isSaas));
  const [columnsReports, setColumnsReports] = useState(fillReportsList(columnsReportsList,props.isSaas));
  const [showFilterList, setShowFilterList] = useState(false);
  const [showModalFiltersReports, setShowModalFiltersReports] = useState(false);
  const [showModalCustomDate, setShowModalCustomDate] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedAdvertisers, setSelectedAdvertisers] = useState([]);
  const [selectedAdvertiserSaas, setSelectedAdvertiserSaas] = useState(userCredentials.role != 1 ? [{id: userCredentials.AdvertiserId}] : []);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedCampaignSaas, setSelectedCampaignSaas] = useState([]);
  const [selectedBillingStatus, setSelectedBillingStatus] = useState([]);
  const [selectedMarket, setSelectedMarket] = useState([]);
  const [selectedInventoryType, setSelectedInventoryType] = useState([]);
  const [selectedLineitems, setSelectedLineitems] = useState([]);
  const [selectedLineitemSaas, setSelectedLineitemSaas] = useState([]);
  const [openColRows, setOpenColRows] = useState(true);
  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [selectedStartEndDate, setSelectedStartEndDate] = useState(null);
  const [applyButton, setApplyButton] = useState(false);
  const [data, setData] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);
  const [reportName, setReportName] = useState('');
  const [clearFilter, setClearFilter] = useState(false);
  const [enableButtonExecute, setEnableButtonExecute] = useState(false);
  const [isCurrencyReport, setIsCurrencyReport] = useState(false);  
  const showFilterListRef = useRef(null);
  const buttonAddRef = useRef(null);

  const mapSelectedRowsToDimensionParameters = (selectedRows, dimensionsMap) => {
    return selectedRows.map(row => {
      const matchingDimension = dimensionsMap.find(dim => dim.dimension === row);
      return matchingDimension ? matchingDimension.dimensionParameter : row;
    });
  };

  const handleExecutePerformanceReport = () => {
    if (selectedDateRange !== '' && selectedDateRange !== 'someOption' && selectedRows.length > 0) {
      setShowSpinner(true);
      setEnableButtonExecute(false);
      const period = (selectedDateRange && selectedDateRange!=="customDate") ? selectedDateRange : null;
      const startDate = selectedDateRange === "customDate" ? selectedStartEndDate.startDate : null;
      const endDate = selectedDateRange === "customDate" ? selectedStartEndDate.endDate : null;
      const advertisers = selectedAdvertisers.map((advertiser) => advertiser.id);
      const campaigns = selectedCampaigns.map((campaign) => campaign.name);
      const selectedBilling = selectedBillingStatus.map((billOption) => billOption.id);
      const markets = selectedMarket.map((market) => market.name);
      const inventoryType = selectedInventoryType.map((inventory) => inventory.name);
      const advertiserSaas = selectedAdvertiserSaas.map((advertiser) => advertiser.id);
      const campaignSaas = selectedCampaignSaas.map((campaign) => campaign.name);
      const advertiserIdSaas = userCredentials.role != 1 ? userCredentials.AdvertiserId : 0;
      const lineitems = selectedLineitems.map((lineitem) => lineitem.id);
      const lineitemSaas = selectedLineitemSaas.map((lineitem) => lineitem.id);
      let clientsId = selectedClients.map((client) => client.id);
      if (clientsId.length == 0 && userCredentials.userAdvertiserClientId.length > 0) {
        clientsId = userCredentials.userAdvertiserClientId;
      }
      props.isSaas ? (
        getPerformanceReportSaas(mapSelectedRowsToDimensionParameters(selectedRows, dimensionMap),
          advertiserSaas,
          advertiserIdSaas,
          campaignSaas,
          startDate,
          endDate,
          period,
          inventoryType,
          clientsId,
          lineitemSaas,
          selectedColumns.includes(i18n.advanceReportsCurrency),
          selectedColumns.includes(i18n.advanceReportsSpentCurrencyDeal),
          selectedColumns.includes(i18n.advanceReportsCpmCurrency),
          selectedColumns.includes(i18n.advanceReportsCpvCurrency),
          selectedColumns.includes(i18n.advanceReportsInversionBudgetCurrency)
        )
        .then((res) => {
          setData(res.data)
          res.data.length > 0 && setIsCurrencyReport(res.data[0].currencyCode == "" ? false : true);
          setSelectedRowsTable(selectedRows)
          setShowSpinner(false);
        })
      ) : (
        getPerformanceReport(mapSelectedRowsToDimensionParameters(selectedRows, dimensionMap),
          advertisers,
          campaigns,
          startDate,
          endDate,
          period,
          selectedBilling,
          inventoryType,
          markets,
          lineitems,
          selectedColumns.includes(i18n.advanceReportsCurrency),
          selectedColumns.includes(i18n.advanceReportsSpentCurrencyDeal),
          selectedColumns.includes(i18n.advanceReportsCpmCurrency),
          selectedColumns.includes(i18n.advanceReportsCpvCurrency),
          selectedColumns.includes(i18n.advanceReportsInversionBudgetCurrency)
        )
        .then((res) => {
          setData(res.data)
          res.data.length > 0 && setIsCurrencyReport(res.data[0].currencyCode == "" ? false : true);
          setSelectedRowsTable(selectedRows)
          setShowSpinner(false);
        })
      )
    }
    setClearFilter(false);
  };

  const handlePerformanceReport = () => {
    if (selectedDateRange !== '' && selectedDateRange !== 'someOption' && selectedRows.length){
      setEnableButtonExecute(true);
    } else {
      setEnableButtonExecute(false);
    }
  };

  useEffect(() => {
    handlePerformanceReport();
  }, [selectedDateRange, selectedRows, selectedStartEndDate]);

  useEffect(() => {
    if(selectedColumns.includes(i18n.advanceReportsCurrency) ||
        selectedColumns.includes(i18n.advanceReportsSpentCurrencyDeal) ||
        selectedColumns.includes(i18n.advanceReportsCpmCurrency) ||
        selectedColumns.includes(i18n.advanceReportsCpvCurrency) ||
        selectedColumns.includes(i18n.advanceReportsInversionBudgetCurrency))
      {
        if(((!isCurrencyReport && data != null) || data == null) && selectedDateRange !== '' &&
            selectedDateRange !== 'someOption' && selectedRows.length) {
          setEnableButtonExecute(true);
        } else {
          setEnableButtonExecute(false);
        }
      }
  }, [selectedColumns]);

  useEffect(() => {
    clearFilter &&
    handlePerformanceReport();
  }, [clearFilter]);

  const getSelectedItems = (filter) => {
    switch (filter) {
      case i18n.advanceReportsAdvertiser:
        return selectedAdvertisers;
      case i18n.advanceReportsCampaign:
        return selectedCampaigns;
      case i18n.advanceReportsBillingStatus:
        return selectedBillingStatus;
      case i18n.advanceReportsMarket:
        return selectedMarket;
      case i18n.advanceReportsInventoryType:
        return selectedInventoryType;
      case i18n.advanceReportsLineItem:
        return selectedLineitems;
      // Saas
      case i18n.advanceReportsAdvertiserSaas:
        return selectedAdvertiserSaas;
      case i18n.advanceReportsClient:
        return selectedClients;
      case i18n.advanceReportsCampaignSaas:
        return selectedCampaignSaas;
      case i18n.advanceReportsLineItemSaas:
        return selectedLineitemSaas;
      default:
        return [];
    }
  };

  const getSetSelectedItems = (filter) => {
    switch (filter) {
      case i18n.advanceReportsAdvertiser:
        return setSelectedAdvertisers;
      case i18n.advanceReportsCampaign:
        return setSelectedCampaigns;
      case i18n.advanceReportsBillingStatus:
        return setSelectedBillingStatus;
      case i18n.advanceReportsMarket:
        return setSelectedMarket;
      case i18n.advanceReportsInventoryType:
        return setSelectedInventoryType;
      case i18n.advanceReportsLineItem:
        return setSelectedLineitems;        
      // Saas
      case i18n.advanceReportsAdvertiserSaas:
        return setSelectedAdvertiserSaas;
      case i18n.advanceReportsClient:
        return setSelectedClients;
      case i18n.advanceReportsCampaignSaas:
        return setSelectedCampaignSaas;
      case i18n.advanceReportsLineItemSaas:
        return setSelectedLineitemSaas;
      default:
        return () => {};
    }
  };

  const handleRowTab = () => {
		setRowTab(true);
		setColumnTab(false);
	}

  const handleColumnTab = () => {
		setRowTab(false);
		setColumnTab(true);
	}

  const handleSearchAttribute = (e) => {
    setSearchTerm(e);
  }

  const handleSearchFilter = (e) => {
    setSearchFilter(e);
  }

  const filteredReports = (reports, searchType) => {
    return reports
      .filter((item) => {
        const valueToSearch = item.title ? item.title : item;
        return valueToSearch.toLowerCase().includes(searchType.toLowerCase());
      })
      .sort((a, b) => {
        const valueA = a.title ? a.title : a;
        const valueB = b.title ? b.title : b;
        return valueA.localeCompare(valueB);
      });
  };

  const handleRowClick = (item) => {
    setSelectedRows([...selectedRows, item])
    setRowReports(rowReports.filter(report => report !== item));
  };

  const handleColumnClick = (item) => {
    setSelectedColumns([...selectedColumns, item]);
    setColumnsReports(columnsReports.filter(report => report !== item));
  };

  const handleCloseFiltersReportsModal = () => {
    setShowModalFiltersReports(false);
  }

  const handleCloseCustomDateModal = () => {
    setShowModalCustomDate(false);
  }

  let lastSelectValue = null;

  const handleReset = () => {
    setSelectedDateRange("someOption");
    setSelectedFilter([]);
    setSelectedRows([]);
    setSelectedColumns([]);
    setRowReports(fillReportsList(rowReportsList,props.isSaas));
    setColumnsReports(fillReportsList(columnsReportsList,props.isSaas));
    setSelectedAdvertisers([]);
    setSelectedCampaigns([]);
    setSelectedBillingStatus([]);
    setSelectedMarket([]);
    setSelectedInventoryType([]);
    setSelectedLineitems([]);
    //Saas
    setSelectedAdvertiserSaas([]);
    setSelectedClients([]);
    setSelectedCampaignSaas([]);
    setShowSpinner(false);
    setData(null);
  };

  const handleDownload = () => {
    const csvData = [
      [
        selectedRows.find(item => item === i18n.advanceReportsAdvertiser) ? i18n.advanceReportsAdvertiser : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCampaign) ? i18n.advanceReportsCampaign : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCampaignId) ? i18n.advanceReportsCampaignId : undefined,
        selectedRows.find(item => item === i18n.advanceReportsLineItem) ? i18n.advanceReportsLineItem : undefined,
        selectedRows.find(item => item === i18n.advanceReportsLineItemId) ? i18n.advanceReportsLineItemId : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCreative) ? i18n.advanceReportsCreative : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCreativeId) ? i18n.advanceReportsCreativeId : undefined,
        selectedRows.find(item => item === i18n.advanceReportsDate) ? i18n.advanceReportsDate : undefined,
        selectedRows.find(item => item === i18n.advanceReportsFormat) ? i18n.advanceReportsFormat : undefined,
        selectedRows.find(item => item === i18n.advanceReportsInventoryType) ? i18n.advanceReportsInventoryType : undefined,
        selectedRows.find(item => item === i18n.advanceReportsOfferType) ? i18n.advanceReportsOfferType : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCountryApp) ? i18n.advanceReportsCountryApp : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCountryYoutube) ? i18n.advanceReportsCountryYoutube : undefined,
        selectedRows.find(item => item === i18n.advanceReportsDevices) ? i18n.advanceReportsDevices : undefined,
        selectedRows.find(item => item === i18n.advanceReportsAppName) ? i18n.advanceReportsAppName : undefined,
        selectedRows.find(item => item === i18n.advanceReportsYoutubeChannel) ? i18n.advanceReportsYoutubeChannel : undefined,
        selectedRows.find(item => item === i18n.advanceReportsIDHSDeal) ? i18n.advanceReportsIDHSDeal : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsImpressions) ? i18n.advanceReportsImpressions : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsViews) ? i18n.advanceReportsViews : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsGoal) ? i18n.advanceReportsGoal : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsClicks) ? i18n.advanceReportsClicks : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsEngagements) ? i18n.advanceReportsEngagements : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsVTR) ? i18n.advanceReportsVTR : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCTR) ? i18n.advanceReportsCTR : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsER) ? i18n.advanceReportsER : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsInversionBudgetCurrency) ? i18n.advanceReportsInversionBudgetCurrency : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsInversionBudgetUSD) ? i18n.advanceReportsInversionBudgetUSD : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsSpentUSD) ? i18n.advanceReportsSpentUSD : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCurrency) ? i18n.advanceReportsCurrency : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsSpentCurrencyDeal) ? i18n.advanceReportsSpentCurrencyDeal : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsStartDate) ? i18n.advanceReportsStartDate : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsEndDate) ? i18n.advanceReportsEndDate : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsQuartiles) ? i18n.advanceReportsFirstQuartile : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsQuartiles) ? i18n.advanceReportsMidQuartile : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsQuartiles) ? i18n.advanceReportsThirdQuartile : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCpm) ? i18n.advanceReportsCpm : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCpv) ? i18n.advanceReportsCpv : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCpmCurrency) ? i18n.advanceReportsCpmCurrency : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCpvCurrency) ? i18n.advanceReportsCpvCurrency : undefined,
      ].filter((item) => item !== undefined),
      ...data.map((item) => ([
        selectedRows.find(item => item === i18n.advanceReportsAdvertiser) ? item.advertiser + (item.advertiserCountry ? ' - ' + item.advertiserCountry : '') : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCampaign) ? item.campaign : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCampaignId) ? item.campaignId : undefined,
        selectedRows.find(item => item === i18n.advanceReportsLineItem) ? item.lineItem : undefined,
        selectedRows.find(item => item === i18n.advanceReportsLineItemId) ? item.lineitemId : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCreative) ? item.creative : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCreativeId) ? (item.creativeId ? item.creativeId : '-') : undefined,
        selectedRows.find(item => item === i18n.advanceReportsDate) ? item.date : undefined,
        selectedRows.find(item => item === i18n.advanceReportsFormat) ? item.format : undefined,
        selectedRows.find(item => item === i18n.advanceReportsInventoryType) ? item.inventoryType : undefined,
        selectedRows.find(item => item === i18n.advanceReportsOfferType) ? item.offerType : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCountryApp) ? item.country : undefined,
        selectedRows.find(item => item === i18n.advanceReportsCountryYoutube) ? item.country : undefined,
        selectedRows.find(item => item === i18n.advanceReportsDevices) ? item.device : undefined,
        selectedRows.find(item => item === i18n.advanceReportsAppName) ? item.placement : undefined,
        selectedRows.find(item => item === i18n.advanceReportsYoutubeChannel) ? item.placement : undefined,
        selectedRows.find(item => item === i18n.advanceReportsIDHSDeal) ? item.dealId : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsImpressions) ? String(item.impressions).replaceAll(',', '') : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsViews) ? (item.completeViewsVideo != null ? String(item.completeViewsVideo).replaceAll(',', '') : '-') : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsGoal) ? (item.goal != null ? String(item.goal).replaceAll(',', '') : '-') : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsClicks) ? String(item.clicks).replaceAll(',', '') : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsEngagements) ? (item.engagements != null ? String(item.engagements).replaceAll(',', '') : '-') : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsVTR) ? (item.vtr != null ? item.vtr.toFixed(2) + "%" : "-") : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCTR) ? (item.ctr != null ? item.ctr.toFixed(2) + "%" : "-") : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsER) ? (item.er != null ? item.er.toFixed(2) + "%" : "-") : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsInversionBudgetCurrency) ? ((item?.inversionBudget.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsInversionBudgetUSD) ? ((item?.inversionBudgetUsd?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsSpentUSD) ? (item.spentUsd ? item.spentUsd.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-') : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCurrency) ? item.currencyCode : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsSpentCurrencyDeal) ? item.spent.toFixed(2).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsStartDate) ? item.startDate.split('T')[0] : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsEndDate) ? item.endDate.split('T')[0] : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsQuartiles) ? (item.firstQuartile != null ? item.firstQuartile : '-') : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsQuartiles) ? (item.midQuartile != null ? item.midQuartile : "-" ): undefined,
        selectedColumns.find(item => item === i18n.advanceReportsQuartiles) ? (item.thirdQuartile != null ? item.thirdQuartile : "-" ) : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCpm) ? (item.cpm != null ? item.cpm.toFixed(2).toLocaleString('en-US') : "-" ) : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCpv) ? (item.cpv != null ? item.cpv.toLocaleString('en-US') : "-" ) : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCpmCurrency) ? (item.cpmCurrency != null ? item.cpmCurrency.toFixed(2).toLocaleString('en-US') : "-" ) : undefined,
        selectedColumns.find(item => item === i18n.advanceReportsCpvCurrency) ? (item.cpvCurrency != null ? item.cpvCurrency.toLocaleString('en-US') : "-" ) : undefined,
      ].filter((item) => item !== undefined)),
      )];
    let date = new Date();
    
    downloadCsv(reportName!='' ? (reportName) + '.csv' : ('reportPerformanceUntitled-' + date.getDate() + (date.getMonth() + 1) + date.getFullYear()) + '.csv', csvData);
  }

  const disabledRowHandle = (item) => {
    let incompatibleItems = [];
  
    for (const selectedRow of selectedRows) {
      const incompatibleItem = incompatibleData.find(
        incompatible => 
          (incompatible.selectedRow === selectedRow && incompatible.incompatibleRow === item) ||
          (incompatible.selectedRow === item && incompatible.incompatibleRow === selectedRow)
      );
      
      if (incompatibleItem) {
        incompatibleItems.push(selectedRow);
      }
    }
  
    for (const selectedColumn of selectedColumns) {
      const incompatibleItem = incompatibleData.find(
        incompatible => 
          (incompatible.selectedColumn === selectedColumn && incompatible.incompatibleRow === item) ||
          (incompatible.selectedRow === item && incompatible.incompatibleColumn === selectedColumn)
      );
      
      if (incompatibleItem) {
        incompatibleItems.push(`column ${selectedColumn}`);
      }
    }
  
    incompatibleData.forEach(incompatible => {
      if (incompatible.selectedInventoryType &&
          selectedInventoryType.some(type => type.name === incompatible.selectedInventoryType) &&
          incompatible.incompatibleRow === item) {
        incompatibleItems.push(`inventory type ${incompatible.selectedInventoryType}`);
      }
    });

    if(((item==i18n.advanceReportsCreative || item==i18n.advanceReportsCreativeId) &&
      incompatibleItems.includes(i18n.advanceReportsDevices)) ||
      (item==i18n.advanceReportsDevices &&
        (selectedRows.includes(i18n.advanceReportsCreative) || selectedRows.includes(i18n.advanceReportsCreativeId)))
      ) {
        if(selectedInventoryType.some(inventoryType => inventoryType.name === i18n.app) &&
        !selectedInventoryType.some(inventoryType => inventoryType.name === i18n.youtube)) {
          return { isDisabled: false, message: '' };          
        } else {
          if(item==i18n.advanceReportsDevices) {
            return { isDisabled: true, message: 'This field is not compatible with Creative and Creative ID. It is only compatible when filtering by Inventory Type App' };
          } else {
            return { isDisabled: true, message: 'This field is not compatible with devices. It is only compatible when filtering by Inventory Type App' };
          }
        }
    }
  
    const isDisabled = incompatibleItems.length > 0;
    const message = isDisabled ? `This field is not compatible with ${incompatibleItems.join(' and ')}` : '';
  
    return { isDisabled, message };
  };
  
  const disabledColumnHandle = (item) => {
    let incompatibleItems = [];
  
    for (const selectedRow of selectedRows) {
      const incompatibleItem = incompatibleData.find(
        incompatible => 
          (incompatible.selectedRow === selectedRow && incompatible.incompatibleColumn === item) ||
          (incompatible.selectedColumn === item && incompatible.incompatibleRow === selectedRow)
      );
      
      if (incompatibleItem) {
        incompatibleItems.push(selectedRow);
      }
    }
  
    for (const selectedColumn of selectedColumns) {
      const incompatibleItem = incompatibleData.find(
        incompatible => 
          (incompatible.selectedColumn === selectedColumn && incompatible.incompatibleColumn === item) ||
          (incompatible.selectedColumn === item && incompatible.incompatibleColumn === selectedColumn)
      );
      
      if (incompatibleItem) {
        incompatibleItems.push(selectedColumn);
      }
    }
  
    incompatibleData.forEach(incompatible => {
      if (incompatible.selectedInventoryType &&
          selectedInventoryType.some(type => type.name === incompatible.selectedInventoryType) &&
          incompatible.incompatibleColumn === item) {
        incompatibleItems.push(`inventory type ${incompatible.selectedInventoryType}`);
      }
    });


    let onlyCompatibleOk = true;

    if (item==i18n.advanceReportsGoal && (!selectedRows.includes(i18n.advanceReportsLineItem) && !selectedRows.includes(i18n.advanceReportsLineItemId))) {
      incompatibleItems.push('goal');
      onlyCompatibleOk = false;
    }

    if (item==i18n.advanceReportsInversionBudgetCurrency && (!selectedRows.includes(i18n.advanceReportsLineItem) && !selectedRows.includes(i18n.advanceReportsLineItemId))) {
      incompatibleItems.push('inversionBudgetCurrency');
      onlyCompatibleOk = false;
    }

    if (item==i18n.advanceReportsInversionBudgetUSD && (!selectedRows.includes(i18n.advanceReportsLineItem) && !selectedRows.includes(i18n.advanceReportsLineItemId))) {
      incompatibleItems.push('inversionBudgetUSD');
      onlyCompatibleOk = false;
    }
  
    const isDisabled = incompatibleItems.length > 0;
    let message = ''
    !onlyCompatibleOk ?
      message = isDisabled ? 'This field is only compatible with Line Item and Line Item Id' : '' :
      message = isDisabled ? `This field is not compatible with ${incompatibleItems.join(' and ')}` : '';
    return { isDisabled, message };
  };

  const handleClickOutside = (event) => {
    if (
      (buttonAddRef.current && buttonAddRef.current.contains(event.target)) ||
      (showFilterListRef.current && showFilterListRef.current.contains(event.target))
    ) {
      return;
    }
    
    setShowFilterList(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const handleClearRowsColumns = () => {
    setRowReports(prevRowReports => [...prevRowReports, ...selectedRows]);
    setColumnsReports(prevColumnsReports => [...prevColumnsReports, ...selectedColumns]);

    setSelectedRows([]);
    setSelectedColumns([]);
  }

  const handleDisableColumns = (item) => {
    if ((item === i18n.advanceReportsLineItem && !selectedRows.includes(i18n.advanceReportsLineItemId)) || 
    (item === i18n.advanceReportsLineItemId && !selectedRows.includes(i18n.advanceReportsLineItem))) {

      if (selectedColumns.includes(i18n.advanceReportsGoal)) {
        setSelectedColumns(prevSelectedColumns => prevSelectedColumns.filter(selectedItem => selectedItem !== i18n.advanceReportsGoal));
        setColumnsReports(prevColumnsReports => [...prevColumnsReports, i18n.advanceReportsGoal]);
      }
      
      if (selectedColumns.includes(i18n.advanceReportsInversionBudgetCurrency)) {
        setSelectedColumns(prevSelectedColumns => prevSelectedColumns.filter(selectedItem => selectedItem !== i18n.advanceReportsInversionBudgetCurrency));
        setColumnsReports(prevColumnsReports => [...prevColumnsReports, i18n.advanceReportsInversionBudgetCurrency]);
      }

      if (selectedColumns.includes(i18n.advanceReportsInversionBudgetUSD)) {
        setSelectedColumns(prevSelectedColumns => prevSelectedColumns.filter(selectedItem => selectedItem !== i18n.advanceReportsInversionBudgetUSD));
        setColumnsReports(prevColumnsReports => [...prevColumnsReports, i18n.advanceReportsInversionBudgetUSD]);
      }
    }
  }

  return (
    <div className="divContentPerformanceReport">
      <div className="divBoxPerformanceReport">
        <section className="divSectionPerformanceReport">
          <Row>
            <Col xs={5}>
              <input
                className="reportInputName lettersPerformanceReport backgroundColorAdvanceReports"
                placeholder={props.isSaas ? "Name of report Saas" : "Name of report"}
                type="text"
                name="name"
                value={reportName}
                onChange={(e) => setReportName(e.target.value.replace(/\./g, ''))}
              />
            </Col>
            <Col xs={5}>
              <select
                className="reportsInputDates lettersPerformanceReport backgroundColorAdvanceReports"
                value={selectedDateRange}
                onFocus={(e) => {
                  if (e.target.value === "customDate") {
                    lastSelectValue = e.target.value;
                    e.target.value = "";
                  }
                }}
                onBlur={(e) => {
                  if (e.target.value === "") e.target.value = lastSelectValue;
                }}
                onChange={(e) => {
                  e.target.value === "customDate"
                    ? setShowModalCustomDate(true)
                    : setSelectedDateRange(e.target.value);
                  lastSelectValue = e.target.value;
                  e.target.blur()
                }}
                
              >
                <option value="someOption">{i18n.dateRange}</option>
                <option value="yesterday">{i18n.yesterday}</option>
                <option value="today">{i18n.today}</option>
                <option value="thisMonth">{i18n.thisMonth}</option>
                <option value="lastSevenDays">{i18n.lastSevenDays}</option>
                <option value="last_month">{i18n.lastMonth}</option>
                <option value="customDate">
                  {selectedDateRange === "customDate" ? selectedStartEndDate.startDate + " - " + selectedStartEndDate.endDate : i18n.customDate}
                </option>
              </select>
            </Col>
            <Col xs={2} className="coldivIconsReports">
              <div className="divIconsReports">
                
                {!data ? (
                  <>
                    <img src={saveIconDisabled} className="display-none reportIcons" /> 
                    <img src={resetIconDisabled} className="reportIcons" /> 
                    <img src={downloadIconDisabled} className="reportIcons" /> 
                  </>
                ) : (
                  <>
                    <img src={saveIcon} className="display-none reportIcons" />
                    <img src={resetIcon} className="reportIcons" onClick={handleReset} />
                    <img src={downloadIcon} className="reportIcons" onClick={handleDownload} />
                  </>
                )
                }
              </div>
            </Col>
          </Row>
        </section>
        <section className="divSectionPerformanceReport">
          <div className="divFiltersPerformanceReport backgroundColorAdvanceReports">
            <p className="pFilters lettersPerformanceReport">Filters</p>
            {selectedAdvertisers.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsAdvertiser)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsAdvertiser}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedAdvertisers([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedCampaigns.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsCampaign)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsCampaign}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCampaigns([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedBillingStatus.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsBillingStatus)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsBillingStatus}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedBillingStatus([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedMarket.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsMarket)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsMarket}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedMarket([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedInventoryType.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsInventoryType)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsInventoryType}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedInventoryType([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedLineitems.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsLineItem)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsLineItem}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedLineitems([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedLineitemSaas.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsLineItemSaas)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsLineItem}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      selectedLineitemSaas([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedAdvertiserSaas.length > 0 && userCredentials.role == 1 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsAdvertiserSaas)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsAdvertiserSaas}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedAdvertiserSaas([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedClients.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {
                  setSelectedFilter(i18n.advanceReportsClient)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsClient}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedClients([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            {selectedCampaignSaas.length > 0 && (
              <button
                className="buttonFilters buttonFiltersVariables"
                onClick={() => {                  
                  setSelectedFilter(i18n.advanceReportsCampaignSaas)
                  setShowModalFiltersReports(true)
                }}
              >
                <div className="divButtonFiltersTitle">
                  {i18n.advanceReportsCampaign}
                </div>
                <div className="divButtonFiltersCancel">
                  <MdOutlineCancel
                    className="cursorPointer"
                    size={16}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedCampaignSaas([]);
                      setClearFilter(true);
                    }}
                  />
                </div>
              </button>
            )}
            <div className="divFilterList">
              <button
                className="btn btn-primary regularButton buttonFilters buttonAdd"
                ref={buttonAddRef}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFilterList(!showFilterList)
                }}
              >
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.25 19v-6.25H5v-1.5h6.25V5h1.5v6.25H19v1.5h-6.25V19h-1.5z"
                    fill="#fff"
                  />
                </svg>
                {i18n.add}
              </button>
              {showFilterList && (
                <div
                  className="divFiltersListPerformanceReport"
                  tabIndex={1}
                  ref={showFilterListRef}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div className="divSearcherFilters">
                    <SearcherInput
                      iconSize={18}
                      onChange={handleSearchFilter}
                      placeholder="Search ..."
                      divStyle="searchDivGrey"
                      inputStyle="searchInputGrey"
                    />
                  </div>
                  <div className="divSearcherFilters cursorPointer lettersPerformanceReport">
                    {props.isSaas ? (
                      filteredReports(filterReportsSaasList, searchFilter).map((item, index) => (
                        (item.allowToWatch==0 || item.allowToWatch==userCredentials.role) &&
                          <p
                            key={index}
                            onClick={() => {
                              setSelectedFilter(item.value)
                              setShowModalFiltersReports(true)
                            }}
                          >
                            {item.title}
                          </p>
                      ))
                    ) : (
                      filteredReports(filterReportsList, searchFilter).map((item, index) => (
                        <p
                          key={index}
                          onClick={() => {
                            setSelectedFilter(item)
                            setShowModalFiltersReports(true)
                          }}
                        >
                          {item}
                        </p>
                      ))
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
        <section className="divSectionPerformanceReport">
          <div className="divRowsColumnsPerformanceReport">
            <Collapse dimension={"width"} in={openColRows}>
              <div className="divCollapseRowColPerformanceReport">
                <div className="divItemsPerformanceReport backgroundColorAdvanceReports">
                  <Row className="rowItemsPerformanceReport">
                    <Col xs={6}>
                      <h5 className="lettersPerformanceReport fontBold">{i18n.advanceReportsSelectItems}</h5>
                      <div className="tabsColumnRow">
                        <Row>
                          <Col xs={6} onClick={handleRowTab} className={`colColumnRows ${rowTab ? 'colReportBorderBottom' : ''}`}>
                            <h5 className={`${rowTab ? 'colReportFont' : ''}`}>{i18n.row}</h5>
                          </Col>
                          <Col xs={6} onClick={handleColumnTab} className={`colColumnRows ${columnTab ? 'colReportBorderBottom' : ''}`}>
                            <h5 className={`${columnTab ? 'colReportFont' : ''}`}>{i18n.column}</h5>
                          </Col>
                        </Row>
                      </div>
                      <div>
                        <SearcherInput
                          iconSize={18}
                          onChange={handleSearchAttribute}
                          placeholder="Search..."
                        />
                        {(selectedRows.length > 0 || selectedColumns.length > 0) && (
                          <button className="btnClearRowsColumns" onClick={handleClearRowsColumns}>{i18n.clearAll}</button>
                        )}
                        <div className={`listColRowReports lettersPerformanceReport ${(selectedRows.length > 0 || selectedColumns.length > 0) ? 'listColRowReportsWithClearButton' : ''}`}>
                        {
                          rowTab ? (
                            <NoneSelectedItems
                              handleItemClick={handleRowClick}
                              itemReports={rowReports}
                              filteredReports={filteredReports}
                              searchTerm={searchTerm}
                              disabledItemHandle={disabledRowHandle}
                            />
                          ) : (
                            <NoneSelectedItems
                              handleItemClick={handleColumnClick}
                              itemReports={columnsReports}
                              filteredReports={filteredReports}
                              searchTerm={searchTerm}
                              disabledItemHandle={disabledColumnHandle}
                            />
                            )
                          }
                        </div>
                      </div>
                    </Col>
                    <Col xs={6}>
                      <div className="divContentSelectedAttributesPerformanceReport">
                        <div className="divSelectedAttributesPerformanceReport">
                          <SelectedItems
                            title={i18n.rows}
                            selectedItems={selectedRows}
                            setSelectedItems={setSelectedRows}
                            setItemsReports={setRowReports}
                            handleDisableColumns={handleDisableColumns}
                          />
                        </div>
                        <div className="divSelectedAttributesPerformanceReport divMarginTopAdvanceReports">
                          <SelectedItems
                            title={i18n.columns}
                            selectedItems={selectedColumns}
                            setSelectedItems={setSelectedColumns}
                            setItemsReports={setColumnsReports}
                          />
                        </div>
                        <div className="divButtonExecute divMarginTopAdvanceReports">
                          <Button
                            text={i18n.execute}
                            title={enableButtonExecute ? i18n.execute : i18n.enableExecuteButton}
                            className={`hundredSizeButton ${!enableButtonExecute ? 'disabledButton' : ''}`}
                            onClick={enableButtonExecute ? handleExecutePerformanceReport : undefined}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Collapse>
            <button className="collapsableButton backgroundColorAdvanceReports" onClick={() => setOpenColRows(!openColRows)}>
              {openColRows ?
                <MdOutlineKeyboardDoubleArrowLeft /> :
                <MdOutlineKeyboardDoubleArrowRight />
              }
            </button>
            {
              showSpinner ? (
                <div className="divWithoutResultsContainer">
                  <div className="marginWithoutResults">
                    <Spinner className="loaderSpinner" animation="border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                </div>
              ) : (
                !data ? (
                  <>
                    <div className="divWithoutResultsContainer">
                      <div className="divWithoutResults marginWithoutResults lettersPerformanceReport">
                        <h3>{i18n.advanceReportsEmpty} </h3>                    
                        <h3>{i18n.advanceReportsGetStarted}</h3>
                      </div>
                    </div>
                  </>
                ) : (
                  data == [] || data.length == 0 ? (
                    <h3>{i18n.advanceReportsNoMatches}</h3>
                  ) : (
                    <div className={`divContentResultsPerformanceReport ${openColRows ? 'contentHalfResultsPerformanceReport' : 'contentFullResultsPerformanceReport'}`}>
                      <div className="divResultsRowColPerformanceReport">
                        <div className="divItemsPerformanceReport divTablePerformanceReportOverflow">
                          <TablePerformanceReport
                            data={data}
                            selectedRows={selectedRowsTable}
                            selectedColumns={selectedColumns}
                            isSaas={props.isSaas}
                            isCurrencyReport={isCurrencyReport}
                          />
                        </div>
                      </div>
                    </div>
                  )
                )
              )
            }
          </div>
        </section>
        <ModalFiltersReports
          show={showModalFiltersReports}
          handleClose={handleCloseFiltersReportsModal}
          title={selectedFilter}
          selectedItems={getSelectedItems(selectedFilter)}
          setSelectedItems={getSetSelectedItems(selectedFilter)}
          setApplyButton={setApplyButton}
          handlePerformanceReport={handlePerformanceReport}
          selectedAdvertisers={selectedAdvertisers}
          selectedAdvertiserSaas={selectedAdvertiserSaas}
          selectedClients={selectedClients}
          selectedCampaigns={selectedCampaigns}
          selectedCampaignSaas={selectedCampaignSaas}
          role={userCredentials.role}
          userCredentialsAdvertiserId={userCredentials.AdvertiserId}
          userCredentialsAdvertiserClientsId={userCredentials.userAdvertiserClientId}
        />

        <ModalCustomDate
          show={showModalCustomDate}
          handleClose={handleCloseCustomDateModal}
          setSelectedStartEndDate={setSelectedStartEndDate}
          setSelectedDateRange={setSelectedDateRange}
          setShowModalCustomDate={setShowModalCustomDate}
        />
      </div>
    </div>
  )
};

export default PerformanceReport;